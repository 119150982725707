<template>
  <div id="app">
    
    <Breadcrumbs></Breadcrumbs>
            

            <!--<div class="breadcrumbs hidden-one col-sm-9">
              <div class="pagetitle">
                <h1></h1>
              </div>
            </div>-->
            <div class="col-sm-12 col-md-9">
              <div id="body" class="body-helper">
                <div id="contentArea">

                  <!-- <div id="testOmni" class="include-file" data-include-file="/OmnitureInteractions.htm"></div> -->
                  
                  <Survey></Survey>

                  <p></p>
                  <!-- Related Nav Area -->
                  <div class="nav-related hidden-three hidden-four">
                    <!--
                        Should contain same content (minus any viewport 3/4 specific content) as
                        the div.nav-related block that appears at the start of the main content block.
                    -->
                  </div>
                  <!-- /end Related Nav Area -->
                  <!-- Persistent Content Area -->
                  <div id="content-secondary"></div>

                </div>
              </div>
            </div>
  </div>
</template>

<script>
import Survey from './components/Survey'
import Breadcrumbs from './components/Breadcrumbs'
export default {
  name: 'App',
  data() {
    return {
      message: "TestM"
    }
  },
  components: {
    Survey,
    Breadcrumbs
  }
}


</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>
