<template>
<div id="Breadcrumbs">
    <div class="breadcrumbs hidden-one span24" id='breadcrumbs'>
        <div class="bread-crumb hidden-one">
        <span class="hidden-one">
            <a class="no-link tp-link-policy" v-bind:href="CDCBaseURL">CDC</a>
        </span>
        <span class="lt hidden-one"></span>
        <span class="hidden-one">
            <a class="no-link tp-link-policy" v-bind:href="YRBSUrl">YRBS</a>
        </span>
        <span class="lt hidden-one"></span>
        <span class="hidden-one">
            <a class="no-link tp-link-policy" v-bind:href="YouthOnlineUrl">Youth Online</a>
        </span>
        <span class="lt hidden-one"></span>
        <span class="hidden-one">
            <a class="no-link tp-link-policy" v-bind:href="CrossTabulationUrl">Cross Tabulation</a>
        </span>
        </div>
    </div>
</div>
</template>
<script>

  import * as ENV from '../env';

  export default {
    name: 'Survey',
    computed: {
      CDCBaseURL: function () {
        var URL = ENV.VUE_APP_CDCBaseURL;
        return URL;
      },
      YRBSUrl: function () {
        var URL = ENV.VUE_APP_CDCBaseURL + "/healthyyouth/data/yrbs/index.htm";
        return URL;
      },
      YouthOnlineUrl: function () {
        var URL = ENV.VUE_APP_NCCDBaseURL + "/youthonline";
        return URL;
      },
      CrossTabulationUrl: function () {
        var URL = "/";
        return URL;
      }
    }
  }
</script>
